import React from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
const ProductStrategy = React.lazy(() => import("../components/ProductStrategy"))

export default function ServicesPage() {
 
  
  

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/product-strategy/" />
      </Helmet>
      <React.Suspense fallback={<div />}>
      <ProductStrategy />
        </React.Suspense>
     
    </Layout>
  )
}
